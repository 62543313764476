
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import GeneralForm from '@/components/article/form/GeneralForm.vue';
import ImageForm from '@/components/article/form/ImageForm.vue';
import MetaForm from '@/components/article/form/MetaForm.vue';
import OptionForm from '@/components/article/form/OptionForm.vue';
import AvailableHourForm from '@/components/article/form/AvailableHourForm.vue';
import Editable from '@/mixins/Editable';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import EditArticleLoader from '@/components/article/EditArticleLoader.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { Constrain } from '@/interfaces/models/Constrain';
import { Tag } from '@/interfaces/models/Tag';
import Filter from '@/interfaces/api/Filter';
import PriceByTypeForm from '@/components/article/form/PriceByTypeForm.vue';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import AllergensAndAdditivesForm from '@/components/article/form/AllergensAndAdditivesForm.vue';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import NutritionForm from '@/components/article/form/NutritionForm.vue';
import FormatPriceInObject from '@/mixins/FormatPriceInObject';
import LoyaltyOption from '@/components/article/form/LoyaltyOption.vue';
import { PiggyReward } from '@/interfaces/models/PiggyReward';
import EditLocalization from '@/mixins/EditLocalization';
import StyleForm from '@/components/article/form/StyleForm.vue';
import WoltArticleOptionsForm from '@/components/article/form/WoltArticleOptionsForm.vue';
import UberEatsArticleOptionsForm from '@/components/article/form/UberEatsArticleOptionsForm.vue';
import JetArticleOptionsForm from '@/components/article/form/JetArticleOptionsForm.vue';

const foodcard = namespace('foodcard');
const reward = namespace('reward');
const tag = namespace('tag');
const constrain = namespace('constrain');
const printGroups = namespace('printGroups');

@Component({
  components: {
    JetArticleOptionsForm,
    UberEatsArticleOptionsForm,
    WoltArticleOptionsForm,
    LoyaltyOption,
    NutritionForm,
    AvailabilityForm,
    PriceByTypeForm,
    EditArticleLoader,
    AvailableHourForm,
    OptionForm,
    MetaForm,
    ImageForm,
    GeneralForm,
    VWrapper,
    AllergensAndAdditivesForm,
    StyleForm,
  },
})
export default class EditArticle extends mixins(
  Editable,
  StackedForm,
  Notification,
  EditArticleMixin,
  FormatPriceInObject,
  EditLocalization,
) {
  @constrain.State('items') public constrains!: Constrain[];
  @constrain.Action('setFilter') public setConstrainFilter!: (f: Filter) => void;
  @constrain.Action('fetch') public getContrains!: any;
  @printGroups.State('items') public printGroups!: PrintGroups[];
  @printGroups.Action('setFilter') public setPrintGroupsFilter!: (f: Filter) => void;
  @printGroups.Action('fetch') public getPrintGroups!: any;
  @tag.State('items') public tags!: Tag[];
  @tag.Action('setFilter') public setTagFilter!: (f: Filter) => void;
  @tag.Action('fetch') public getTags!: any;

  @foodcard.State('categories') public categories!: Category[];

  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;

  @foodcard.Action('fetchArticleNames') public getArticleNames: any;
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;

  @reward.Action('fetch') public getRewards!: any;
  @reward.Action('resetRewards') public resetRewards!: any;
  @reward.Action('setFilter') public setRewardsFilter!: any;
  @reward.State('items') public rewards!: PiggyReward[];

  public $refs!: {
    general: InstanceType<typeof GeneralForm> & { getData: () => any };
    image: InstanceType<typeof ImageForm> & { getData: () => any };
    meta: InstanceType<typeof MetaForm> & { getData: () => any };
    option: InstanceType<typeof OptionForm> & { getData: () => any };
    price: InstanceType<typeof PriceByTypeForm> & { getData: () => any };
    displayPrice: InstanceType<typeof PriceByTypeForm> & { getData: () => any };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
    compound: InstanceType<typeof AllergensAndAdditivesForm> & { getData: () => any };
    nutritions: InstanceType<typeof NutritionForm> & { getData: () => any };
    loyalty: InstanceType<typeof LoyaltyOption> & { getData: () => any };
    style: InstanceType<typeof StyleForm> & { getData: () => any };
    wolt: InstanceType<typeof WoltArticleOptionsForm> & { getData: () => any };
    uberEats: InstanceType<typeof UberEatsArticleOptionsForm> & { getData: () => any };
    jet: InstanceType<typeof JetArticleOptionsForm> & { getData: () => any };
  };

  public isLoading: boolean = this.$isLoading('venue') || this.$isLoading('option');

  public async mounted() {
    this.$startLoading('venue');
    this.$startLoading('option');
    this.$startLoading('category');
    this.$startLoading('tags');
    this.$startLoading('constrains');
    this.$startLoading('article');

    if (this.venue && this.venue._id) {
      this.setCategoryFilter({ venue: this.venue._id });
      this.setOptionFilter({ venue: this.venue._id });
      this.setTagFilter({ venue: this.venue._id });
      this.setConstrainFilter({ venue: this.venue._id });
      this.setPrintGroupsFilter({ venue: this.venue._id });

      await Promise.all([
        this.getCategories(),
        this.getArticleNames({ venue: this.venue._id }),
        this.getOptions(),
        this.getTags(),
        this.getContrains(),
        this.getPrintGroups(),
      ]);

      if (this.venue && this.venue._id && this.venue.piggyLoyaltyEnabled) {
        this.setRewardsFilter({ venue: this.venue._id });
        await this.getRewards();
      } else {
        await this.resetRewards();
      }
    }

    if (this.editing) {
      await this.show({ id: this.id, category: this.$route.params.category });
    }
    this.$stopAllLoading();
  }

  get filteredRewards() {
    const activePiggyUuid = this.article?.piggyRewardUuid;
    return this.rewards.filter((reward: PiggyReward) => {
      if (!reward.smoothrid || activePiggyUuid === reward.uuid) {
        return reward;
      }
    });
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue) {
      this.$startLoading('option');
      this.$startLoading('category');
      this.setCategoryFilter({ venue: this.venue._id });
      this.setOptionFilter({ venue: this.venue._id });
      await this.getOptions({ venue: this.venue._id });
      await this.getCategories();
      this.$stopAllLoading();
      if (this.venue.customerGroup === 'the_ash') {
        this.setRewardsFilter({ customerGroup: this.venue.customerGroup });
        await this.getRewards();
      } else {
        await this.resetRewards();
      }
    }
  }

  protected async save() {
    Promise.all(await this.validate()).then(async (res: any) => {
      if (this.isValid(res)) {
        const data: Article = this.getMergedData();
        if (this.venue && this.venue.customerGroup !== 'the_ash') {
          delete data.piggyRewardUuid;
        }
        // TODO - may mutate unexpected fields
        const arr = ['price', 'displayPrice', 'standard', 'inside', 'takeAway', 'delivery'];
        arr.map((type: any) => {
          // mutate merged data
          const res = this.deepSearchItems(data, type, (k: any, v: any) => {
            if (v && v.includes) {
              return v.includes(',');
            }
          });
          res.map((obj: any) => {
            obj[type] = obj[type].replaceAll(',', '.');
          });
        });
        if (this.editing) {
          data.id = this.active._id;
          if (data.category !== this.$route.params.category) {
            await this.newCategory({
              category: this.$route.params.category,
              id: this.active._id,
              newId: data.category,
            });
          }

          if (this.active && this.active.description && data.description) {
            data.description = this.serializeLocalization(this.active.description, data.description);
          }

          await this.update(data);
        } else {
          await this.store(data);
        }
        if (!this.active) {
          this.$router.push({ name: 'article.index' });
          return;
        }

        let formData: FormData;
        if (data.assets && data.assets instanceof File) {
          formData = new FormData();
          formData.append('asset', data.assets);
          await this.uploadImage({ ...data, image: formData });
        }
        if (data.banner && data.banner instanceof File) {
          formData = new FormData();
          formData.append('asset', data.banner);
          await this.uploadBanner({ ...data, image: formData });
        }
        if (data.icon && data.icon instanceof File) {
          formData = new FormData();
          formData.append('asset', data.icon);
          await this.uploadIcon({ ...data, image: formData });
        }
        this.$router.push({ name: 'article.index', query: { ...this.$route.query } });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  protected cancel() {
    this.$router.push({ name: 'article.index', query: { ...this.$route.query } });
  }

  protected getMergedData() {
    const woltSettings = this.$refs?.wolt?.getData();
    if (woltSettings?.price === '') {
      delete woltSettings['price'];
    }

    const jetSettings = this.$refs?.jet?.getData();
    if (jetSettings?.price === '') {
      delete jetSettings['price'];
    }

    const uberEatsSettings = this.$refs?.uberEats?.getData();
    if (uberEatsSettings?.price === '') {
      delete uberEatsSettings['price'];
    }

    const externalOrderProviderSettings = [woltSettings, jetSettings, uberEatsSettings].filter(Boolean);

    return {
      ...this.$refs.general.getData(),
      ...this.$refs.image.getData(),
      ...this.$refs.meta.getData(),
      ...this.$refs.option.getData(),
      ...this.$refs.availableHours.getData(),
      ...this.$refs.nutritions.getData(),
      ...this.$refs.style.getData(),
      priceByType: {
        ...this.$refs.price.getData(),
      },
      priceLockByType: {
        ...this.$refs.price.getDataPriceLockByType(),
      },
      prices: [...this.$refs.price.getPrices()],
      displayPriceByType: {
        ...this.$refs.displayPrice.getData(),
      },
      availability: {
        ...this.$refs.availability.getData(),
      },
      compound: {
        ...this.$refs.compound.getData(),
      },
      externalOrderProviderSettings,
      ...this.$refs.loyalty.getData().loyalty,
    };
  }
}
